<template>
  <div class="login">
    <!-- <div class="login-invite" v-if="inviterMobile !== ''">
      您被尾号{{
        inviterMobile.substring(inviterMobile.length - 4)
      }}邀请免费使用云算房计价软件
    </div> -->
    <!-- <div class="login-title"></div> -->
    <div class="login-form">
      <div class="login-form-username">
        <van-field
          v-model="username"
          class="login-form-username-field"
          placeholder="联系人"
        />
      </div>
      <div class="login-form-mobile">
        <van-field
          v-model="mobile"
          class="login-form-mobile-field"
          type="tel"
          maxlength="11"
          placeholder="手机号"
        />
      </div>
      <div class="login-form-code">
        <div class="login-form-code-input">
          <van-field
            v-model="smscode"
            class="login-form-code-input-field"
            maxlength="6"
            type="tel"
            placeholder="短信验证码"
          />
        </div>
        <div class="login-form-code-action">
          <van-button
            type="primary"
            :disabled="codeDisabled"
            class="login-form-code-action-btn"
            @click="onClickSendSms"
          >
            {{ codeText }}
          </van-button>
        </div>
      </div>
      <div class="agree-checkbox">
        <van-checkbox :icon-size="14" v-model="checked">我已阅读并同意云算房</van-checkbox
        ><span
          class="xy"
          @click="
            agreementShow = true;
            xyIndex = 1;
          "
          >《服务协议》</span
        >及<span
          class="xy"
          @click="
            agreementShow = true;
            xyIndex = 2;
          "
          >《隐私保护协议》</span
        >
      </div>
      <div class="login-form-login" @click="onClickLogin"></div>
      <!-- 弹窗同意协议 -->
      <van-dialog
        v-model="agreeConfrim"
        title="云算房服务协议及隐私保护"
        :show-cancel-button="false"
        :show-confirm-button="false"
      >
        <div class="agree-tip">
          为了更好的保障您的合法权益，请阅读并同意以下协议
          <span
            class="xy"
            @click="
              agreementShow = true;
              xyIndex = 1;
            "
            >《云算房服务协议》</span
          >
          及
          <span
            class="xy"
            @click="
              agreementShow = true;
              xyIndex = 2;
            "
            >《云算房隐私保护协议》</span
          >，同意后 验证码将发送到您的手机。
        </div>
        <div class="agree-btn">
          <van-button type="default" @click="agreeConfrim = false">不同意</van-button>
          <van-button
            type="info"
            @click="
              agreeConfrim = false;
              checked = true;
            "
            >同意</van-button
          >
        </div>
      </van-dialog>
      <!-- 活动规则 tag-->
      <div class="rightRuleTag" @click="ruleShow = true">
        <div class="rightRuleTagContent">查<br />看<br />活<br />动<br />规<br />则</div>
      </div>
      <van-popup v-model="ruleShow" closeable round :style="{ width: '80%' }">
        <ActivityRule></ActivityRule>
      </van-popup>
      <van-popup
        v-model="agreementShow"
        closeable
        position="bottom"
        :style="{ height: '100%' }"
      >
        <div class="agreement">
          <div class="agreement-title">
            {{ xyIndex == 1 ? "云算房服务协议" : "云算房隐私保护协议" }}
          </div>
          <div class="agreement-content">
            <iframe
              v-if="xyIndex == 1"
              style="width: 100%"
              :style="{ height: screenHeight + 'px' }"
              src="/yhxy.html"
              frameborder="0"
            ></iframe>
            <iframe
              v-if="xyIndex == 2"
              style="width: 100%"
              :style="{ height: screenHeight + 'px' }"
              src="/ysxy.html"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </van-popup>
    </div>
    <loading :text="text" v-if="loading"></loading>
    <div v-if="!completeService && this.isWeXin">
      <CompleteService @click="onClickCompleteService"></CompleteService>
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
import Vue from "vue";
import ActivityRule from "./activity-rule.vue";
import loading from "@/views/components/loading.vue";
import CompleteService from "@/components/CompleteService/index.vue";
import {
  mobile_smsCode,
  mobile_login,
  applyRecommend,
  mobile_verify,
  getUserAgency,
} from "@/api/invite";
import { checkUserOpenId } from "@/api/withdraw";
export default Vue.extend({
  components: { ActivityRule, loading, CompleteService },
  props: {
    inviterMobile: {
      type: String,
      default: () => {
        return "";
      },
    },
    invitationMethod: {
      type: String,
      default: () => {
        return "0";
      },
    },
    isFormLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      xyIndex: "",
      screenHeight: "",
      text: "登录中...",
      loading: false,
      username: "",
      mobile: "",
      smscode: "",
      codeText: "获取验证码",
      codeDisabled: false,
      timer: null,
      checked: false,
      agreeConfrim: false,
      ruleShow: false,
      agreementShow: false,
      newUser: "",
      completeService: false,
      isWeXin: false,
      hadBindOpenId: false,
    };
  },
  methods: {
    onClickSendSms() {
      if (!this.codeDisabled) {
        if (!this.mobile || !/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.mobile)) {
          Notify("请输入正确手机号");
        } else {
          if (!this.timer) {
            let time = 60;
            this.codeText = `${time}秒后重新获取`;
            this.codeDisabled = true;
            this.timer = setInterval(() => {
              if (time > 1) {
                time = time - 1;
                this.codeText = `${time}秒后重新获取`;
              } else {
                clearInterval(this.timer);
                this.codeText = "重新发送";
                this.codeDisabled = false;
                this.timer = null;
              }
            }, 1000);
          }
          //发送验证码
          mobile_smsCode(this.mobile).then((res) => {
            // if (res.code == 200) {
            // }
          });
        }
      }
    },
    onClickLogin() {
      if (!this.checked) {
        this.agreeConfrim = true;
        return false;
      }
      if (this.username === "") {
        Notify("请输入联系人名称");
      } else if (!this.mobile || !/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.mobile)) {
        Notify("请输入正确手机号");
      } else if (this.smscode === "") {
        Notify("请输入验证码");
      } else {
        //登录
        this.loading = true;
        if (this.isFormLogin) {
          //登录页
          this.loginFormIndex();
        } else {
          //分享页
          this.mobileVerify();
        }
      }
    },
    mobileVerify() {
      mobile_verify(this.mobile).then((res) => {
        console.log(9999, res);
        if (res == "手机号未被占用") {
          this.newUser = 1;
        } else {
          this.newUser = 0;
        }
        // if (res.code == 200) {
        // }
        this.loginFormIndex();
      });
    },

    loginFormIndex() {
      const formData = new FormData();
      formData.append("grant_type", "sms");
      formData.append("loginChannel", "PLATFORM_DEFAULT");
      formData.append("scope", "all");
      formData.append("mobile", this.mobile);
      formData.append("sms_code", this.smscode);
      formData.append("contacts", this.username);
      formData.append("loginType", 11);
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      mobile_login(formData).then((res) => {
        this.$store.commit("SET_INVITE_TOKEN", res.access_token);
        localStorage.setItem("inviteToken", res.access_token);
        this.$store.commit("SET_CURRENT_LOGIN_MOBILE", this.mobile);
        localStorage.setItem("currentLoginMobile", this.mobile);
        sessionStorage.setItem("isOnline", true);
        // if (this.inviterMobile !== '' && this.inviterMobile !== this.mobile) {
        //   this.ApplyRecommend();
        // }
        // let info = `inviterMobile,${this.mobile}_invitationMethod,1`; //info好像这版本没用

        getUserAgency(res.access_token)
          .then((res) => {
            if (res) {
              localStorage.setItem("AGENCY_CODE", res);
              this.$store.commit("SET_AGENCY_CODE", res);
              console.log(1111, res);
              if (this.isFormLogin) {
                this.loading = false;
                //登录页
                // this.$router.replace({
                //   path: `/openBetaShareEwm?inviterMobile=${this.mobile}`,
                // });
                this.BindUserOpenId();
              } else {
                //分享页
                this.ApplyRecommend();
              }
            }
          })
          .then(() => {
            this.loading = false;
          });
      });
    },

    ApplyRecommend() {
      let reqData = {
        invitationMethod: this.invitationMethod,
        contacts: this.username,
        inviteeMobile: this.mobile,
        inviterMobile: this.inviterMobile,
        newUser: this.newUser,
      };
      window.sessionStorage.setItem("isSubmitToken", "NoExit");
      applyRecommend(reqData, this.$store.getters.inviteToken)
        .then((res) => {
          this.loading = false;
          if (res) {
            Notify({
              type: "success",
              message: "您已成功注册成为云算房用户，正在为您跳转活动参与页中…",
            });
            setTimeout(() => {
              this.BindUserOpenId();
            }, 1000);
          } else {
            Notify({
              type: "success",
              message: "您已经是平台用户或被其它好友邀请，正在为您跳转页面…",
            });
            setTimeout(() => {
              this.BindUserOpenId();
            }, 1000);
          }
        })
        .catch(() => {
          this.loading = false;
          setTimeout(() => {
            this.BindUserOpenId();
          }, 1000);
        });
    },
    BindUserOpenId() {
      if (this.completeService) {
        //点击了获取全部服务
        //先判断当前用户是否已绑定OpenId
        if (this.isWeXin) {
          checkUserOpenId(this.$store.getters.inviteToken)
            .then((res) => {
              if (!res) {
                //未绑定OpenId
                const scope = "snsapi_base"; //弹出授权页面
                let currentUrl = window.location.href;
                if (currentUrl.includes("/openBetaInvite")) {
                  currentUrl = currentUrl.replace(
                    "/openBetaInvite",
                    `/openBetaShareEwm?inviterMobile=${this.mobile}`
                  );
                } else if (currentUrl.includes("/openBetaShare")) {
                  currentUrl = currentUrl.replace(
                    "/openBetaShare",
                    `/openBetaShareEwm?inviterMobile=${this.mobile}`
                  );
                }

                console.log(currentUrl);
                const baseUrlEncode = encodeURIComponent(currentUrl);
                const appid = "wx17081ce61493aba9";
                const urls = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${baseUrlEncode}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`;
                location.replace(urls);
              } else {
                this.$router.push({
                  path: `/openBetaShareEwm?inviterMobile=${this.mobile}`,
                });
              }
            })
            .catch(() => {
              this.$router.push({
                path: `/openBetaShareEwm?inviterMobile=${this.mobile}`,
              });
            });
        } else {
          this.$router.push({
            path: `/openBetaShareEwm?inviterMobile=${this.mobile}`,
          });
        }
      } else {
        this.$router.push({
          path: `/openBetaShareEwm?inviterMobile=${this.mobile}`,
        });
      }
    },
    onClickCompleteService() {
      this.completeService = true;
    },
  },
  mounted() {
    const ua = window.navigator.userAgent.toLowerCase();
    this.isWeXin = ua.indexOf("micromessenger") !== -1;
    this.screenHeight = window.innerHeight - 55;
  },
});
</script>
<style lang="less" scoped>
.login {
  background-color: #fff;
  padding-bottom: 30px;
  margin: 0 20px;
  border-radius: 10px;
  padding-top: 15px;
  &-invite {
    font-size: 13px;
    color: #fb5b03;
    text-align: center;
    margin-bottom: 20px;
  }
  &-title {
    background-image: url("../../../assets/img/invite/login_title.png");
    background-size: 100% 100%;
    width: 190px;
    height: 20px;
    margin: 0 auto;
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18px;
    .agree-checkbox {
      width: 280px;
      font-size: 11px;
      padding: 4px 0 20px 0;
      /deep/.van-checkbox,
      /deep/.van-checkbox__icon,
      /deep/.van-checkbox__icon .van-icon {
        display: inline;
      }
      /deep/.van-checkbox__label {
        line-height: 11px;
      }
      .xy {
        color: #316cff;
      }
    }
    &-username {
      width: 280px;
      margin-bottom: 10px;
      &-field {
        background-color: #f0f4ff;
        border-radius: 5px;
      }
    }
    &-mobile {
      margin-bottom: 10px;
      width: 280px;
      &-field {
        background-color: #f0f4ff;
        border-radius: 5px;
      }
    }
    &-code {
      display: flex;
      align-items: center;
      width: 280px;
      margin-bottom: 20px;
      &-input {
        flex: 1;
        &-field {
          background-color: #f0f4ff;
          border-radius: 5px;
        }
      }
      &-action {
        font-size: 0px;
        margin-left: 10px;
        &-btn {
          border-radius: 5px;
          background-color: #fce40f;
          border-color: #fce40f;
          color: #391916;
          width: 140px;
        }
      }
    }
    &-login {
      width: 280px;
      height: 50px;
      background-image: url("../../../assets/img/invite/openbeta-login.png");
      background-size: 100% 100%;
    }
  }
}
/deep/.van-dialog__header {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.agree-tip {
  font-size: 12px;
  padding: 20px 30px;
  color: #000;
  .xy {
    color: #316cff;
  }
}
.agree-btn {
  /deep/.van-button {
    width: 94px;
  }
  /deep/.van-button--default {
    background: #f0f4ff;
  }
  display: flex;
  justify-content: space-between;
  padding: 0 60px;
  padding-bottom: 24px;
}

// @keyframes rightRuleTagContent {
//   0% {
//     transform: translateX(0);
//   }
//   25% {
//     transform: translateX(-5px);
//   }
//   50% {
//     transform: translateX(5px);
//   }
//   75% {
//     transform: translateX(-5px);
//   }
//   100% {
//     transform: translateX(5px);
//   }
// }
@keyframes rightRuleTag {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.rightRuleTagContent {
  animation: rightRuleTag 1.5s; /* 抖动的持续时间 */
  animation-iteration-count: 1; /* 抖动的次数，这里设置为无限次 */
}
.rightRuleTag {
  position: fixed;
  z-index: 5;
  top: 200px;
  right: 0;
  width: 28px;
  height: 106px;
  // background: linear-gradient(-15deg, #deeefd, #ffffff);
  padding: 10px 0;
  // box-shadow: 0px 1px 9px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
  // border-radius: 5px 0 0 5px;
  font-weight: bold;
  font-size: 12px;
  color: #2a1000;
  line-height: 15px;
  background: url("~@/assets/img/invite/rule_bg.png") center top no-repeat;
  background-size: 100%;
}

.agreement {
  font-size: 12px;
  color: #333;
  &-title {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }
  &-content {
    padding: 0 15px;
  }
}
</style>
